<template>
    <div>
        <div class="container">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item  style="font-size: 20px;">
                    <i class="el-icon-s-custom"></i> <span>潜在客户开发池（人有我无）</span>
                </el-breadcrumb-item>
            </el-breadcrumb>
            <div v-if="active==0">
                <div style="display:flex;">
                    <el-form ref="form" :model="curForm" label-width="120px" label-position="left" style="margin-top:20px;">
                        <el-row>
                            <!-- <el-date-picker
                                v-model="value2"
                                type="daterange"
                                align="right"
                                unlink-panels
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                :picker-options="pickerOptions">
                                </el-date-picker> -->
                        </el-row>
                        <el-row>
                            <el-form-item  label="监测周期" >    
                                <el-date-picker                                
                                            v-model="curForm.dateArray"
                                            style="width:322px;"
                                            type="daterange"
                                            unlink-panels
                                            range-separator="至"
                                            start-placeholder="开始日期"
                                            end-placeholder="结束日期"
                                            :clearable="false"
                                            :shortcuts="shortcuts"
                                            @change ="handleChange"
                                            :picker-options="pickerOptions"
                                            >
                                </el-date-picker>
                                <el-button style="margin-left:10px;" type="text" @click="setDate(1)">近一周</el-button>
                                <el-button type="text" @click="setDate(2)">近一月</el-button>
                                <el-button type="text" @click="setDate(3)">近三月</el-button>
                            </el-form-item>  
                        </el-row>
                        <!-- <el-row>
                            <el-form-item label="目标市场" >
                                <el-cascader :show-all-levels="false" style="width:800px;" placeholder="请选择目标市场" :options="intelligenceAreaList"
                                    :props="props" v-model="curForm.stationIdArr" clearable></el-cascader>
                            </el-form-item>    
                        </el-row> -->
                        <el-form-item label="竞争对手">
                            <el-select v-model="curForm.competitiveArr" style="width:413px;" multiple clearable @change ="handleChange" placeholder="请选择关注的竞争对手">
                                <el-option v-for="item in MediaOwnerList" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <!-- <el-row>    
                            <el-form-item label="目标行业">
                                <el-select v-model="curForm.industryId" style="width:213px;" multiple clearable placeholder="请选择目标行业">
                                    <el-option v-for="item in categoryList" :key="item.value" :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="关注品牌" >
                                <el-input placeholder="品牌名称" v-model="curForm.brand" ></el-input>
                            </el-form-item>    
                        </el-row>
                        <el-divider content-position="left"></el-divider>
                        <el-row>
                            <el-form-item label="统计维度" >
                                <el-select v-model="curForm.dimention" style="width:213px;"  placeholder="请选择统计维度">
                                    <el-option v-for="item in dementionList" :key="item" :label="item" :value="item">
                                    </el-option>
                                </el-select>                            
                            </el-form-item>    
                        </el-row> -->
                    </el-form>
                </div>
                <el-divider content-position="left"></el-divider>

                <div class="card-group" style="display:flex;justify-content:space-around;"> 
                    <div class="group-item" style="display:flex;align-items:top;" v-for="(item,index) in competitiveList" :key="index">
                        <div style="margin-left:5px; margin-top:25px;">
                            <el-card class="box-card">
                                <div style="display:flex;">
                                    <div  >
                                        <div class="clearfix" style="height: 25px;">
                                            <el-button   class="product-name" style="margin-left:40px;" type="text" >{{item.cateName}}</el-button><span>（曝光频次前十）</span>
                                        </div>
                                    
                                        <div  class="text item" style="margin-left:8px;">                                            
                                            <div style="margin-top:20px;">
                                                <div class="group-item" style="display:flex;align-items:center;" v-for="(itembrands,indexb) in item.brands" :key="indexb">
                                                    <span>{{indexb+1<10?'&nbsp;'+(indexb+1):indexb+1}}</span>
                                                    <el-image                                                    
                                                        style="width: 100px;height:80px;border-radius: 20px;margin-left:10px;"
                                                        :src="itembrands.img"
                                                        :preview-src-list="itembrands.imgList"
                                                        :fit="contain"></el-image>
                                                        <el-button type="text" style="margin-left:20px;" @click="onClickBrand(itembrands,item.cateId)">{{itembrands.brand}}</el-button>
                                                    
                                                </div>
                                                
                                                
                                                <!-- <el-row>
                                                    <el-col :span="3" class="subtitle">折扣</el-col>
                                                    <el-col :span="21">{{itemsub.discount}}折</el-col>
                                                </el-row>   
                                                <el-row style="margin-top:5px;">
                                                    <el-col :span="3" class="subtitle">说明</el-col>
                                                    <el-col :span="21">{{itemsub.description}}</el-col>
                                                </el-row>                                                     -->
                                            </div>                                                
                                        </div>
                                    </div>
                                </div>                                         
                            </el-card>
                        </div>
                    </div>  
                </div> 
            </div>
            <div v-if="active==1">
                <div style="display:flex;justify-content: space-between;margin-top:40px;" >
                    <el-button class="product-name" style="margin-left:40px;" type="text" >{{curBrandName}}</el-button>
                    <el-button type="primary" @click="onReturn(0)">返回</el-button>
                </div>

                <div style="display:flex;justify-content:space-around;">
                    <div style="width:40%;line-height:2.0;">
                        <el-card class="cardstyle" shadow="hover">
                            <div slot="header" class="clearfix">
                                <span><i class="el-icon-s-unfold" style="color:#3B54C5;"></i>投放效果</span>
                                <!-- <el-button :disabled="changed" style="float: right; padding: 3px 0" type="text" @click="onSaveProject">保存方案</el-button> -->
                            </div>
                            <el-divider style="margin-top:-5px;"></el-divider>
                        <el-form ref="form" :model="saveForm" label-width="150px" label-position="left" >
                    <el-row style="margin-top:20px;">
                            <el-form-item label="投放周期" >    
                                <!-- style="width:405px;"               -->
                                <el-date-picker                                
                                    v-model="saveForm.dateArray"
                                    style="width:262px;"
                                    type="daterange"
                                    unlink-panels
                                    range-separator="至"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期"                                   
                                    :clearable="false"
                                    :shortcuts="shortcuts"
                                    @change ="handleDateChange"
                                    >
                                </el-date-picker>
                            </el-form-item>                              
                        </el-row>
                       
                        <!-- <el-row v-if="curItem.haveLed=='True'" > -->
                            <el-form-item  label="版本时长" style="width:372px;">                            
                                <el-select @change="lengthChanged" v-model="saveForm.length"  placeholder="请选择版本时长">
                                    <el-option
                                    v-for="item in lengthList"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>

                            <el-form-item label="每日投放频次" style="width:372px;">                            
                                <el-select @change="timesChanged" v-model="saveForm.times"  placeholder="请选择每日投放频次">
                                    <el-option
                                    v-for="item in timesList"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        <!-- </el-row> -->
                        </el-form>
                        </el-card>
                    </div>
                    <div style="width:20%;line-height:2.0;">  
                        <el-card class="cardstyle" style="margin-left: 0px; height: 220px;"  shadow="hover">
                            <div slot="header" class="clearfix" style="margin-top: -7px;">
                                <span><i class="el-icon-s-unfold" style="color:#3B54C5;"></i>折扣设置</span>                            
                            </div>
                            <el-divider style="margin-top: -5px;"></el-divider>                            
                            <el-form ref="discountform" :model="discountform" label-width="50px" label-position="left" >
                                <el-form-item label="LED" style="margin-left:25px;">   
                                    <!-- @change="onSlideChange(1)" -->
                                    <el-input-number style="width: 135px;" v-model="discountform.ledDiscount" :precision="1" :step="0.1" :min="1" :max="10"></el-input-number>
                                </el-form-item>
                                <el-form-item label="灯箱" style="margin-left:25px;">   
                                    <el-input-number style="width: 135px;" v-model="discountform.dxDiscount" :precision="1" :step="0.1" :min="1" :max="10"></el-input-number>
                                </el-form-item>
                                <!-- <el-form-item label="列车" style="margin-left:25px;">   
                                    <el-input-number style="width: 135px;" v-model="discountform.lcDiscount" :precision="1" :step="0.1" :min="1" :max="10"></el-input-number>
                                </el-form-item> -->

                            </el-form>
                        </el-card>            
                    </div>
                    <div style="width:40%;line-height:2.0;">
                        <el-card class="cardstyle" shadow="hover">
                            <div slot="header" style="margin-top: -5px;">
                                <span><i class="el-icon-s-unfold" style="color:#3B54C5;"></i>投放效果</span>
                                <el-button :disabled="changed" style="float: right; padding: 3px 0;    margin-top: -5px;" type="text" @click="onSaveProject">保存方案</el-button>
                            </div>
                            <el-divider style="margin-top:-5px;"></el-divider>
                            <el-row>                               
                                <el-col v-if="discountform.ledDiscount<10 " :span="10" class="grid-num-small">折扣费用(万元):</el-col>
                                <el-col v-else :span="10" class="grid-num-small">刊例费用:</el-col>
                                <el-col :span="14">￥{{budget}}(万元)</el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="10">
                                    <el-tooltip class="item" effect="dark" placement="right" >
                                        <template v-slot:content> 
                                            <div >
                                                <ul>
                                                    <li>即Cost per mill, 是指某一个营销活动，平均获得一千人次的接触度所需要花费的成本，以货币单位表示.</li>
                                                    <li>该指标可用于评估广告投放的经济性，评估不同市场的广告成本效益</li>
                                                </ul>
                                            </div>
                                        </template>
                                        <span class="grid-num-small">CPM:</span>
                                    </el-tooltip>
                                </el-col>
                                <el-col :span="14">￥{{resultData.CPM}}(元)</el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="10">
                                    <el-tooltip class="item" effect="dark" placement="left-start" content="某个广告在一定时期内被观众接触的总人次，以千人次表示" >
                                        <span class="grid-num-small">累计接触度:</span>
                                    </el-tooltip>
                                </el-col>
                                <el-col :span="14"><span >{{resultData.Frequency}}(千人次)</span></el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="10">
                                    <el-tooltip class="item" effect="dark" placement="left-start" >
                                        <template v-slot:content> 
                                            <div >
                                                <ul>
                                                    <li>到达率是一个时间上的纵向累积指标，它考察特定时间段内观众收看某一媒体或广告<br/>
                                                        （或某一广告计划所能覆盖）的不重复的人数（或比例），反映了接触的受众规模。</li>
                                                    <li>通俗解释到达率(千人)就是看到该广告的总人数</li>
                                                </ul>
                                            </div>
                                        </template>                        
                                        <span class="grid-num-small">到达率:</span>                        
                                    </el-tooltip>
                                </el-col>
                                <el-col :span="14"><span >{{resultData.chudarenshu}}(千人)</span></el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="10">
                                    <el-tooltip class="item" effect="dark" placement="left-start"  >
                                        <template v-slot:content> 
                                            <div >
                                                <ul>
                                                    <li>一个广告排期每条或总体被观众收看的次数</li>
                                                    <li>暴露频次用于广告投放计划传播深度的评估</li>
                                                    <li>算法：累计接触度(千人次)除以到达率(千人)</li>
                                                </ul>
                                            </div>
                                        </template>
                                        <span class="grid-num-small">暴露频次:</span>
                                    </el-tooltip>
                                </el-col>
                                <el-col :span="14"><span>{{resultData.Reach}}(次)</span></el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="18"></el-col>
                                <el-col :span="6"><el-button  type="primary" @click="calculate()">投放效果预测</el-button></el-col>
                            </el-row>
                        </el-card>
                    </div>
                    
                    <!-- <div>
                            <el-button type="primary" @click="active=0">返回</el-button>
                    </div>                     -->
                </div>

                    
                <div v-if="selectedCityList.length>1" style="display:flex;justify-content:space-between;">
                    <el-button   type="text" @click="onSelectMultiCity()">批量选择资源</el-button>
                </div>  
                <el-divider style="margin-top:-0px;"></el-divider>
                <!-- :data="curBrandAreas" -->
                <el-input  v-model="searchkey" style="width:600px;" placeholder="可输入关键字进行检索"></el-input><br/>
                <el-table                       
                    :data="curBrandAreas.filter(data => !searchkey || data.city.toLowerCase().includes(searchkey.toLowerCase()) || data.area.toLowerCase().includes(searchkey.toLowerCase()))"                           
                    border :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                    :row-key="getCityRowKey" 
                    @selection-change="handleSelectionChange"
                    style="width:100%;margin-top:5px;" ref="Table">
                    <el-table-column type="selection" reserve-selection width="55" label="选择"></el-table-column>	
                    <el-table-column prop="city" align="center" label="城市" ></el-table-column>                    
                    <el-table-column prop="area" align="center" label="大区" ></el-table-column>  
                    <el-table-column prop="id" align="center" label="选择资源" width="530">
                        <template v-slot="scope">
                            <!-- <div style="display:flex;"> -->
                                <!-- <el-button type="text" @click="onSelectOneCity(scope.row)">{{scope.row.seleMediaGroup.length>0?'已选'+scope.row.seleMediaGroup.length+'个':'选择资源'}}</el-button> -->
                                <span style="color:#409EFF;cursor:pointer;" @click="onSelectOneCity(scope.row)">{{scope.row.seleMediaGroupStr}}</span>

                            <!-- </div> -->
                        </template>
                    </el-table-column>                   
                </el-table>
            </div>
            <div v-if="active==2">
                <div style="display:flex;justify-content: flex-end;margin-top:20px;" >
                    <el-button type="primary" @click="onReturn(1)">返回</el-button>
                </div>
                <div style="display:flex;justify-content: space-between;margin-top:20px;" >
                    <el-button class="product-name" style="margin-left:40px;" type="text" >{{curCities}}</el-button>                    
                </div>

                <!-- :data="cityPackageList.slice((mediaGroup.currentPage-1)*mediaGroup.pageSize,mediaGroup.currentPage*mediaGroup.pageSize)"                             -->
                <el-input  v-model="searchMediakey" style="width:600px;" placeholder="可输入关键字进行检索"></el-input><br/>
                <el-table 
                    ref="multipleTable"
                    :data="cityPackageList.filter(data => !searchMediakey || data.area.toLowerCase().includes(searchMediakey.toLowerCase()) || data.province.toLowerCase().includes(searchMediakey.toLowerCase())
                         || data.station.toLowerCase().includes(searchMediakey.toLowerCase()) || data.medias.toLowerCase().includes(searchMediakey.toLowerCase())
                         || data.position.toLowerCase().includes(searchMediakey.toLowerCase()) )"                            
                    border
                    resizable
                    :row-key="getPackageRowKey"                              
                    :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                    style="width: 100%;margin-top: 20px;font-size: x-small;"
                    @selection-change="handlePackageSelectionChange">	
                    <el-table-column :selectable="checkbox_select" type="selection" reserve-selection width="55"></el-table-column>	
                    <el-table-column prop="area" align="center"  width="100"  sortable label="大区"></el-table-column>
                    <el-table-column prop="province" align="center"  width="80"   label="省份"></el-table-column>
                    <el-table-column prop="medias" align="center"   width="220"  sortable label="站点">
                        <template v-slot="scope">    
                            <div style="color: #409EFF;cursor: pointer;"  @click="openStation(scope.row.station)"><span style="font-size:x-large">{{scope.row.station}}</span></div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="typeName" align="center"   width="100"  label="资源类型"></el-table-column>
                    <el-table-column prop="position" align="center"   width="80"  label="位置"></el-table-column>
                    <el-table-column prop="name" align="center"   width="120" sortable label="套餐名"></el-table-column>
                    <el-table-column prop="medias" align="center"   sortable label="媒体资源(尺寸m*m/面积㎡)">
                        <template v-slot="scope">
                            <div v-if="scope.row.collapse==-1"><!-- 无需折叠 -->
                                <div class="group-item" v-for="(item,index) in scope.row.mediaInfos" :key="index">
                                    <div v-if="item.images.length>0" style="color: #409EFF;cursor: pointer;" @click="ViewImage(item.images)">{{item.mediaCode}}</div>
                                    <div v-else>{{item.mediaCode}}</div>
                                </div>
                            </div>
                            <div v-else-if="scope.row.collapse==1"><!-- 折叠状态 -->
                                <div class="group-item" v-for="(item,index) in scope.row.mediaInfos" :key="index">
                                    <div v-if="index<4">
                                        <div v-if="item.images.length>0" style="color: #409EFF;cursor: pointer;" @click="ViewImage(item.images)">{{item.mediaCode}}</div>
                                        <div v-else>{{item.mediaCode}}</div>                                                
                                    </div>
                                </div>
                                <i class="el-icon-arrow-down" style="cursor:pointer;" @click="onCollapse(scope.row)"></i>
                            </div>
                            <div v-else><!-- 展开状态 -->
                                <div class="group-item" v-for="(item,index) in scope.row.mediaInfos" :key="index">
                                    <div v-if="item.images.length>0" style="color: #409EFF;cursor: pointer;" @click="ViewImage(item.images)">{{item.mediaCode}}</div>
                                    <div v-else>{{item.mediaCode}}</div>                                            
                                </div>
                                <i class="el-icon-arrow-up" style="cursor:pointer;" @click="onCollapse(scope.row)"></i>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="totalCount" align="center"  width="50"  label="资源数量"></el-table-column>
                    <el-table-column prop="medias" align="center"   width="110"  label="刊例(万元)">
                        <template v-slot="scope">
                            <div v-if="scope.row.cost && scope.row.cost>0">
                                {{scope.row.cost}}
                            </div>
                            <div v-else>
                                <div v-if="scope.row.cost15">{{scope.row.cost15}}(/15秒)</div>
                                <div v-if="scope.row.cost10">{{scope.row.cost10}}(/10秒)</div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="GDP" align="center"  width="80"  label="城市GDP(亿)"></el-table-column>                      
                </el-table>  
                <div style="display:flex;justify-content: center; margin-top:20px;">
                    <el-button type="primary" @click="onSelectPackage()">选定(已选择{{selectedPackageList.length}}个套餐)</el-button>    
                    <el-button style="margin-left:40px;" type="primary"  @click="onReturn(1)">返回</el-button> 
                </div>
                <!-- <el-pagination 
                    @size-change="handleMediaSizeChange"
                    @current-change="handleMediaCurrentChange"
                    :current-page="mediaGroup.currentPage"
                    :page-sizes="[20,50, 100]"
                    :page-size="mediaGroup.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="cityPackageList.length">
                </el-pagination>       -->
                
                <el-dialog title="车站介绍" v-model="viewStationVisible" width="80%"  @open="open()"
                                    :modal-append-to-body='false'
                                    :close-on-click-modal='false'
                                    v-if="viewStationVisible"
                                    append-to-body>
                    <div style="height:2800px;">
                        <station @close="viewStationVisible=false" :station="station"/>
                    </div>
                </el-dialog>   

                <el-dialog title="资源图片" v-model="viewMediaVisible" width="40%" @open="open()"
                                    :modal-append-to-body='false'
                                    :close-on-click-modal='false'
                                    append-to-body>
                    <media-detail @close="viewMediaVisible=false" :image-data="imageList"/>
                </el-dialog>
            </div>
            <!-- <el-dialog title="设置投放计划" v-model="setPlanVisible" width="40%" @open="open()"
                    :modal-append-to-body='false'
                    :close-on-click-modal='false'
                    v-if="setPlanVisible"
                    append-to-body>
                <set-plan @close="setPlanVisible=false" :cur-item="curItem" :table-data="planData" :selected-list="toPlanList"/>
            </el-dialog> -->
            <el-dialog title="保存方案" v-model="setSaveDialogVisible" width="40%" @open="open()"
                                        :modal-append-to-body='false'
                                        :close-on-click-modal='false'
                                        append-to-body>                
                <set-save  @close="setSaveDialogVisible=false" 
                    :total-price="budget" 
                    :led-discount="discountform.ledDiscount" 
                    :dx-discount="discountform.dxDiscount" 
                    :lc-discount="discountform.lcDiscount" 
                    :smart-result="transferData"
                    :cart-list="saveList" 
                    :cateid = "curCateId"
                    :brandname = "curBrandName"/>
            </el-dialog>
            
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getMonthDateArrayByPrePostSafari2,getNextMonthDateArray } from "@/utils/time";
import MediaDetail from '@/views/Business/Smart/MediaDetail';
import Station from '@/views/Business/Station';
import SetPlan from '@/views/Business/Smart/SetPlan';
import SetSave from './SetSave.vue';

export default {
    name: 'baseform',
    components: { 
        MediaDetail,Station,SetPlan,SetSave
        // ,SelectProduct
      },
    computed: {
        ...mapGetters(['intelligenceAreaList','categoryList','MediaOwnerList','competitiveList','cityPackageList','CalculateMultiSmart_WithoutBudget'])
    },
    data() {
        return {
            mediaGroup:{
                // 当前页
                currentPage: 1,
                // 每页多少条
                pageSize: 20,
            },

            curForm: {    
                dateArray:getMonthDateArrayByPrePostSafari2({date: new Date(), preDelayNum: -1, postDelayNum: 0, formatValue: 'yyyy-M'}),
                // stationIdArr:[],
                // industryId:[],
                // brand:'',
                // dimention:'',
                competitiveArr:[],
            },
            props: { multiple: true },
            active:0,
            curCateId:0,
            curBrandName:'',
            selectedCityList:[],
            curBrandAreas:[],
            curCities:'',
            selectedPackageList:[],

            viewStationVisible:false,
            viewMediaVisible:false,
            imageList:[],
            station:[],

            searchkey:'',
            searchMediakey:'',

            currentCityGroupIds:[],//当前城市（或多个城市）所选中的groupId
            setSaveDialogVisible:false,

            //保存项目
            // curItem:{},
            saveList:[],
            saveForm:{
                dateArray:getNextMonthDateArray(),
                length:'15',
                times:100,                
            },
            discountform:{
                ledDiscount:10,
                dxDiscount:10,
                lcDiscount:10,
            },
            lengthList:[{label:'10秒',value:'10'},{label:'15秒',value:'15'}],
            timesList:[{label:'100次',value:100},{label:'200次',value:200},{label:'300次',value:300},{label:'500次',value:500},
                {label:'1000次',value:1000}],

            resultData:{
                CPM:0,
                Frequency:0,
                chudarenshu:0,
                Reach:0,
            },
            budget:0,
            transferData:{},     
            
            value2:'',
             pickerOptions: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                    picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                    picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                    picker.$emit('pick', [start, end]);
                    }
                }]
            },
        };
    },

    async mounted() {
        await this.$store.dispatch('operate/intelligenceAreaList');
        await this.$store.dispatch('operate/categoryList');
        await this.$store.dispatch('operate/MediaOwnerList');
        // await this.$store.dispatch('operate/competitiveList');
        
        // await this.$store.dispatch('operate/productList');

        // const s = document.createElement('script');
        // s.type = 'text/javascript';
        // s.src = 'http://pv.sohu.com/cityjson?ie=utf-8&ver=' + Math.random();
        // document.body.appendChild(s);
        this.onQuery();
    },


    methods: {
        setDate(flag){
            const end = new Date();
            const start = new Date();
            if (flag==1){                
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);                
                    // picker.$emit('pick', );
            }else if(flag==2){
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            }else{
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            }
            this.curForm.dateArray=[start, end];
            this.onQuery();
        },
        handleChange(){
            this.onQuery();
        },

        onReturn(flag){
            this.active=flag;
        },

        ViewImage(item){
            this.imageList = item;
            this.viewMediaVisible=true;
        },

        openStation(station){
            this.station=station;
            this.viewStationVisible=true;
        },

        onCollapse(item){
            console.log('collapse')
            if (item.collapse==1){
                item.collapse=0;
            }else{
                item.collapse=1;
            }
        },

        handleSelectionChange(val) {	
            this.selectedCityList = val;				
        },

        handlePackageSelectionChange(val){
            this.selectedPackageList=val;
        },

        onSelectPackage(){
            // console.log('aa',this.selectedPackageList);
            this.selectedPackageList.forEach(selEle => {
                this.curBrandAreas.forEach(ele => {
                    if (ele.city==selEle.city){
                        // console.log('ele.seleMediaGroup',selEle)
                        if (ele.seleMediaGroup?.length>0){
                            let index = ele.seleMediaGroup.findIndex(val => val.groupId === selEle.groupId);
                            if (index == -1){
                                ele.seleMediaGroup.push({groupId:selEle.groupId,cost10:selEle.cost10,cost15:selEle.cost15,cost:selEle.cost,typeName:selEle.typeName,groupLevel:1,typeIds:selEle.typeIds,name:selEle.name,station:selEle.station});
                            }
                        }else{
                            ele.seleMediaGroup.push({groupId:selEle.groupId,cost10:selEle.cost10,cost15:selEle.cost15,cost:selEle.cost,typeName:selEle.typeName,groupLevel:1,typeIds:selEle.typeIds,name:selEle.name,station:selEle.station});
                        }
                        
                    }
                });
            });

            //获得每个车站下的套餐
            
            this.curBrandAreas.forEach(ele => {
                var seleMediaGroupStation=[];
                ele.seleMediaGroup.forEach(element => {
                    if (seleMediaGroupStation?.length>0){
                        let index = seleMediaGroupStation.findIndex(val => val.station === element.station);
                        if (index == -1){
                            seleMediaGroupStation.push({station:element.station,packages:[element.name]});                        
                        }else{
                            seleMediaGroupStation[index].packages.push(element.name);
                        }
                    }else{
                        seleMediaGroupStation.push({station:element.station,packages:[element.name]});
                    }
                });

                var seleMediaGroupStr="";
                // var stations='';
                var packagestr='';
                seleMediaGroupStation.forEach(statEle => {
                    packagestr='';
                    statEle.packages.forEach(packEle => {
                        if (packagestr==""){
                            packagestr=packEle;
                        }else{
                            packagestr=packagestr+'/'+packEle;
                        }
                    });
                    if (seleMediaGroupStr==""){
                        seleMediaGroupStr = statEle.station+':'+packagestr;
                    }else{
                        seleMediaGroupStr = seleMediaGroupStr+';'+statEle.station+':'+packagestr;
                    }
                });
                if (seleMediaGroupStr==''){
                    seleMediaGroupStr = '选择资源'
                }
                ele.seleMediaGroupStr = seleMediaGroupStr;
            });
            

            this.selectedPackageList=[];
            this.$refs.multipleTable.clearSelection();
            // this.calculate();
            this.active=1;
            // console.log('aa',this.curBrandAreas)
        },

        checkbox_select(row, index){
            if (row.useRate<100 && row.saleStatus=='正常')
                return 1;
            else
                return 0;
        },

        onSelectMultiCity(){
            var cities='';
            this.currentCityGroupIds=[];
            this.selectedCityList.forEach(element => {
                if (cities==""){
                    cities = element.city;
                }else{
                    cities=cities+','+element.city;
                }         
                element.seleMediaGroup.forEach(mg => {
                    this.currentCityGroupIds.push(mg);
                });       
            });
            this.curCities = cities;
            this.searchMediakey='';
            this.getCityPackage(cities);
            // 
        },

        onSaveProject(){
            var groupIds='';
            // this.toPlanList=[];
            // this.curItem.groupLevel=1;
            // this.curItem.name='批量套餐资源';
            // this.curItem.haveLed='False';
            // this.curItem.haveDigitalDx='False';
            // this.curItem.canFree=0;
            // var cost10=0;
            // var cost15=0;
            const {dateArray: [sYM, eYM], times,length} = this.saveForm; 
            const {ledDiscount,dxDiscount,lcDiscount} = this.discountform;  
            if (!sYM){
                this.$message({message: '请选择投放日期',type: 'error'});
                return;
            }
            if (!times){
                this.$message({message: '请选择每日投放频次',type: 'error'});
                return;
            }
            if (!length){
                this.$message({message: '请选择版本时长',type: 'error'});
                return;
            }

            var sDate = new Date(sYM).toLocaleDateString();
            var eDate = new Date(eYM).toLocaleDateString();


            this.saveList=[];
            this.curBrandAreas.forEach(city => {
                city.seleMediaGroup.forEach(element => {
                    if (groupIds==''){
                        groupIds=element.groupId+'';
                    }else{
                        groupIds=groupIds+','+element.groupId+'';
                    }
                    // if (element.typeName=='LED'){
                    //     this.curItem.haveLed='True';
                    //     cost10=cost10+element.cost10*1;
                    //     cost15=cost15+element.cost15*1;
                    // }else{
                    //     cost10=cost10+element.cost*1;
                    // }
                    // this.toPlanList.push(element);
                    var tmp={};
                    tmp.sDate=sDate;
                    tmp.eDate=eDate;
                    tmp.groupId=element.groupId;
                    tmp.groupLevel=1;                
                    tmp.times=times;
                    tmp.length=length;
                    // if (ele.isFree){
                    //     tmp.totalPrice=0;
                    // }
                    // else{
                        tmp.totalPrice=-1;
                    // }
                    tmp.haveSelectListIds='';
                    tmp.adjLog='';
                    tmp.freeIds='';

                    var obj={};
                    obj.count=1;
                    obj.groupId=element.groupId;
                    obj.detailId=-1;
                    obj.item=tmp;
                    obj.weaponId=0;//this.weaponId;
                    this.saveList.push(obj);

                });
            });  
            // this.curItem.cost10=cost10;
            // this.curItem.cost15=cost15;
            // this.curItem.cost=cost10;
           
            
                   
            
            if (groupIds==''){
                this.$message({message: '请选中计划投放的套餐后再保存',type: 'error'});
                return;
            }
            this.calculate().then(()=>{
                this.transferData={
                    Frequency:this.resultData.Frequency,
                    chudarenshu:this.resultData.chudarenshu,
                    Reach:this.resultData.Reach,
                    CPM:this.resultData.CPM,
                    budget:this.budget,
                    industryId:'',
                }     
                this.setSaveDialogVisible=true;
            })
            

            // console.log(this.budget,this.discountform.ledDiscount,this.transferData,this.saveList);


            // this.toPlanList = this.selectedList;

            // console.log('groupIds',groupIds);
        },

        async calculate(){            
            
            this.changed=false;
            const {dateArray: [sYM, eYM], times,length} = this.saveForm; 
            const {ledDiscount,dxDiscount,lcDiscount} = this.discountform; 
            if (!sYM){
                this.$message({message: '请选择投放日期',type: 'error'});
                return;
            }
            var sDate = new Date(sYM).toLocaleDateString();
            var eDate = new Date(eYM).toLocaleDateString();

            var calcuList=[];
            this.curBrandAreas.forEach(city => {
                city.seleMediaGroup.forEach(element => {
                    var tmp={}
                    tmp.sDate=sDate,
                    tmp.eDate=eDate,
                    tmp.times=times;
                    tmp.length=length;
                    tmp.groupId=element.groupId;
                    tmp.isFree=false;//ele.isFree;
                    calcuList.push(tmp);
                })
            });

            if (calcuList.length<1){
                this.$message({message: '未选择资源，无法试算',type: 'error'});
                return;
            }
            // this.productList.forEach(ele => {
            //     // ele.detailId=-1;
            //     if (ele.selected==true){
                    
            //     }
            // });
            var condi = JSON.stringify(calcuList);

            // console.log('condi',condi)
            var ip = localStorage.getItem('Ip');
            var cityName = localStorage.getItem('cityName');
            
            const loading = this.$loading({
                lock: true,
                text: '智能计算中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            await this.$store.dispatch('operate/CalculateMultiSmart_WithoutBudget',{ip,cityName,ledDiscount:ledDiscount,dxDiscount,lcDiscount,condi}).then(res=>{
                    this.resultData=this.CalculateMultiSmart_WithoutBudget;                     
                    this.budget = this.resultData.feiyong;

                    loading.close();
                    // this.viewMediaVisible=true;
                });

            // await this.$store.dispatch('operate/CalculateMultiSmart',{ip,cityName,condi}).then(res=>{
            //     loading.close();
            // });
        },
        

        //设置选中状态
        toggleSelection() {            
            this.currentCityGroupIds.forEach(element => {
                for (let i = 0; i < this.cityPackageList.length; i++) {
                    // console.log('aaa',this.cityPackageList[i].groupId , element.groupId)
                    if (this.cityPackageList[i].groupId == element.groupId) {
                        // console.log('aaaa',this.$refs,this.$refs.multipleTable)
                        this.$refs.multipleTable.toggleRowSelection(this.cityPackageList[i])
                    }
                }
            });           
            
        },

        onSelectOneCity(item){
            this.getCityPackage(item.city);
            this.currentCityGroupIds=[];
            item.seleMediaGroup.forEach(mg => {
                this.currentCityGroupIds.push(mg);
            });       
        },

        getCityPackage(cities){
            this.active=2;
            this.$store.dispatch('competitive/cityPackageList', {cities}).then(res=>{
                this.toggleSelection();
            });
        },

        getCityRowKey(row) {
            return  row.city;//Math.random()+'';//
        },     
        
        getPackageRowKey(row) {
            return row.groupId;
        },     

       onQuery(){
            const{competitiveArr,dateArray:[sYM, eYM]} = this.curForm;
            var sDate = new Date(sYM).toLocaleDateString();
            var eDate = new Date(eYM).toLocaleDateString();
            var ownerIds='';
            if (competitiveArr.length>0){
                ownerIds = competitiveArr.join(',');
            }
            this.$store.dispatch('competitive/competitiveList', {sDate,eDate,ownerIds}).then(res=>{
                
                this.competitiveList.forEach(ele => {
                    ele.brands.forEach(eleBrand => {
                        eleBrand.areas.forEach(eleArea => {
                            eleArea.seleMediaGroup=[];
                            eleArea.seleMediaGroupStr='选择资源';
                            // console.log(eleArea)
                            // eleArea.forEach(eleCity => {
                            //     eleCity.seleMediaGroup=[];
                            // });
                        });
                    });
                });
                // console.log('competitiveList',this.competitiveList)
            });
       },

       onClickBrand(items,cateId){
        // console.log('items',items)
        this.curCateId = cateId*1;
        this.curBrandName=items.brand;
        this.curBrandAreas=items.areas;
        this.active=1;
       },

        // 每页多少条
        handleMediaSizeChange(val) {
            this.mediaGroup.pageSize = val;
        },
        // 当前页
        handleMediaCurrentChange(val) {
            this.mediaGroup.currentPage = val;
        },

        onBack(){
            this.active=0;
        },

        // openStation(station){
        //     this.station=station;
        //     this.viewStationVisible=true;
        // },

        openMedia(items){
            this.imageList=items;
            this.viewMediaVisible=true;
        },
        
        getRowKey(row) {
            return row.projectId;
        },


        // 每页多少条
        handleSizeChange(val) {
            this.pageSize = val;
        },
        handleppmlSizeChange(val) {
            this.ppml.pageSize = val;
        },
        // 当前页
        handleCurrentChange(val) {
            this.currentPage = val;
        },
        handleppmlCurrentChange(val) {
            this.ppml.currentPage = val;
        },

        open() {
            this.$nextTick(() => {

            })
        },
    }
};
</script>

<style scoped>
/deep/  .el-card{
    border: 0px solid #EBEEF5;
}
.slash{
    width:100px;
    height:1px;    
    transform:rotate(-40deg);
    margin-top: -15px;
    margin-left: -45px;
    /* display:flex;
    justify-content: center;
    align-items: center; */
   
}
.tag{
    cursor: pointer;margin-left:15px;
    font-size: larger;
}
.tagSel{
    cursor: pointer;margin-left:15px;
    font-size:x-large;
    background-color: #409EFF;
    color: white;
}

.ok{
    border-bottom:30px solid green;
}

.delay{
    border-bottom:30px solid red;
}

.ready{
    border-bottom:30px solid #999;
}

.empty{
    border-bottom:30px solid #999;
}

.slash div{
    margin-left:30px;
    padding-top: 7px;
}


.sub-title{
    font-size: large;
    margin-top:4px;
}
.item-pre{
    font-size: larger;
}
.button-footer{
    display: flex;
    flex-direction:row-reverse;
}
.product-name{
    font-size: x-large;
    /* margin-left:25px; */
    margin-top:-20px;
}
.card-group{
    display:flex;
    flex-wrap:wrap;
    justify-content:center;
}
.group-item{
    padding: 1px;
    margin-left: 1px;
   
}
.box-card {
    width: 350px;
    
  }
   .text {
    font-size: 14px;
  }

  .item {
    /* margin-bottom: 18px; */
    margin-top:18px;
  }

  /* .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }
  .clearfix:after {
    clear: both
  } */
 

.transition-box {
    margin-bottom: 10px;
    width: 200px;
    height: 100px;
    border-radius: 4px;
    background-color: #409EFF;
    text-align: center;
    color: #fff;
    padding: 40px 20px;
    box-sizing: border-box;
    margin-right: 20px;
  }

.schart-box {
    display: inline-block;
    margin: 20px;
}
.schart {
    width: 600px;
    height: 400px;
}
.content-title {
    clear: both;
    font-weight: 400;
    line-height: 50px;
    margin: 10px 0;
    font-size: 22px;
    color: #1f2f3d;
}


.grid-content {
    display: flex;
    align-items: center;
    flex-direction:column;
    /* height: 120px; */
}

.grid-subcontent {
    display: flex;
    align-items: center;
    height: 50px;
}

.grid-cont-right {
    flex: 1;
    text-align: center;
    font-size: 14px;
    color: #999;
}

.grid-num {
    font-size: 15px;
    /* font-weight: bold; */
}

.grid-num-small {
    font-size: 16px;
    font-weight: bold;
}

.grid-subnum {
    font-size: 20px;
    font-weight: bold;
}

.grid-con-icon {
    font-size: 50px;
    width: 100px;
    height: 100px;
    text-align: center;
    line-height: 100px;
    color: #fff;
    border-radius: 50%;
    /* margin-left: 80px; */
}
.grid-subcon-icon{
    font-size: 30px;
    width: 50px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    color: #fff;
}

.grid-con-0 .grid-subcon-icon {
    background: rgb(184, 223, 223);
}

.grid-con-0 .grid-num {
    color: rgb(184, 223, 223);
}

.grid-con-1 .grid-con-icon {
    background: rgb(45, 140, 240);
}

.grid-con-1 .grid-num {
    color: rgb(45, 140, 240);
}

.grid-con-2 .grid-con-icon {
    background: rgb(100, 213, 114);
}

.grid-con-2 .grid-num {
    color: rgb(45, 140, 240);
}

.grid-con-3 .grid-con-icon {
    background: rgb(242, 94, 67);
}

.grid-con-3 .grid-num {
    color: rgb(242, 94, 67);
}

.grid-con-4 .grid-con-icon {
    background: rgb(132, 94, 67);
}

.grid-con-4 .grid-num {
    color: rgb(132, 94, 67);
}

.grid-con-5 .grid-con-icon {
    background: rgb(66, 94, 67);
}

.grid-con-5 .grid-num {
    color: rgb(66, 94, 67);
}

.grid-con-6 .grid-con-icon {
    background: rgb(66, 136, 67);
}

.grid-con-6 .grid-num {
    color: rgb(66, 136, 67);
}

.clearfix{
    margin-top: -5px;
}
.subtitle{
    font-weight: bolder;
    padding-right: 5px;
}


</style>